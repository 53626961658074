import { useRef, useState } from "react"
import { usePdf } from "@mikecousins/react-pdf"
import { Box, CircularProgress } from "@mui/material"
import { peopleAnalyticsReportChannel } from "lib/event-bus-channels"
import { I18n } from "i18n-js"

import translations from "../locales.json"
import GenerateReportButton from "components/generate_report_button"

const i18n = new I18n(translations)

interface Props {
  file_path: string
  can_regenerate?: boolean
  show_spinner?: boolean
  loader_text?: string
}

const defaultProps = {
  can_regenerate: false,
  show_spinner: true,
  loader_text: "",
}

const PdfRenderer = (props: Props) => {
  props = { ...defaultProps, ...props }

  const filePath = props.file_path
  const canRegenerate = props.can_regenerate
  const showSpinner = props.show_spinner
  const loaderText = props.loader_text
  const [page, setPage] = useState(1)
  const canvasRef = useRef(null)

  const { pdfDocument } = usePdf({
    file: filePath,
    page,
    canvasRef,
    workerSrc: window.location.origin + "/pdf.worker.js",
    onDocumentLoadSuccess: function () {
      peopleAnalyticsReportChannel.emit("onPdfLoadResult", true)
    },
    onDocumentLoadFail: function () {
      peopleAnalyticsReportChannel.emit("onPdfLoadResult", false)
    },
  })

  return (
    <Box sx={{ textAlign: "center" }}>
      {!pdfDocument && showSpinner && (
        <CircularProgress
          className="align-middle text-unloq-brightgreen"
          color="inherit"
        />
      )}

      {!pdfDocument && loaderText != "" && <h2>{loaderText}</h2>}

      <canvas className="pdf-renderer" ref={canvasRef} />

      {Boolean(pdfDocument && pdfDocument.numPages) && (
        <Box className="inline-flex rounded-md py-6" role="group">
          <Box>
            <button
              type="button"
              className="btn btn--secondary"
              style={{ borderWidth: 2 }}
              disabled={page === 1}
              onClick={() => setPage(page - 1)}
            >
              {i18n.t("react.admin.pagination.previous")}
            </button>
          </Box>
          <Box className="pl-4">
            <button
              type="button"
              className="btn btn--secondary"
              style={{ borderWidth: 2 }}
              disabled={page === pdfDocument.numPages}
              onClick={() => setPage(page + 1)}
            >
              {i18n.t("react.admin.pagination.next")}
            </button>
          </Box>
          {canRegenerate && (
            <Box className="pl-8">
              <GenerateReportButton regenerate={!!filePath} />
            </Box>
          )}
        </Box>
      )}
    </Box>
  )
}

PdfRenderer.displayName = "PdfRenderer"

export default PdfRenderer

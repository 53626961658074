import { useState } from "react"
import { useSelector } from "react-redux"
import { Box, CircularProgress } from "@mui/material"
import { Download, InfoOutlined } from "@mui/icons-material"
import axios from "axios"
import { saveAs } from "file-saver"
import { I18n } from "i18n-js"

import translations from "../locales.json"
import { RootState } from "lib/store"

const i18n = new I18n(translations)

const DownloadReportButton = () => {
  const [isDownloading, setIsDownloading] = useState(false)

  const filePath = useSelector(
    (state: RootState) => state.programMemberships.filePath
  )
  const isLoading = useSelector(
    (state: RootState) => state.programMemberships.isLoading
  )

  async function downloadPdf() {
    setIsDownloading(true)

    const response = await axios.get(filePath, {
      responseType: "blob",
    })

    const contentDisposition = response.headers["content-disposition"]
    let filename = "report.pdf"

    if (contentDisposition) {
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
      const matches = filenameRegex.exec(contentDisposition)

      if (matches != null && matches[1]) {
        filename = matches[1].replace(/['"]/g, "")
      }
    }

    const pdfBlob = new Blob([response.data], { type: "application/pdf" })

    saveAs(pdfBlob, filename)

    setIsDownloading(false)
  }

  return (
    <Box
      sx={{ m: 0, position: "relative" }}
      className="flex items-center justify-center"
    >
      {!isLoading && filePath && (
        <>
          <button
            type="submit"
            className="btn btn--primary"
            value="submit"
            disabled={isDownloading}
            onClick={downloadPdf}
          >
            <Download fontSize="small" sx={{ marginRight: 1 }} />

            {isDownloading &&
              i18n.t("react.admin.people_analytics.btn_download_report_active")}
            {!isDownloading &&
              i18n.t("react.admin.people_analytics.btn_download_report")}
          </button>
          {isDownloading && (
            <CircularProgress
              color="inherit"
              size={24}
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                marginTop: "-12px",
                marginLeft: "-12px",
              }}
            />
          )}
          <div className="tooltip flex relative cursor-pointer ml-3">
            <InfoOutlined fontSize="small" sx={{ marginRight: 1 }} />
            <span className="tooltiptext tooltiptext-top">
              {i18n.t("react.admin.people_analytics.btn_download_report_hint")}{" "}
            </span>
          </div>
        </>
      )}
    </Box>
  )
}

DownloadReportButton.displayName = "DownloadReportButton"

export default DownloadReportButton
